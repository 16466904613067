import React, { useLayoutEffect, useMemo } from 'react'
import { useSubheader } from '../../../../_metronic/layout';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from "@apollo/client";
import SVG from "react-inlinesvg";
import TableProviderProduct from './TableProviderProduct';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory, useLocation, useParams} from "react-router-dom";
import { Card, CardBody } from '../../../../_metronic/_partials/controls';
import query_sme_brands from '../../../../graphql/query_sme_brands';
import queryString from 'querystring'
import LoadingDialog from "../../FrameImage/LoadingDialog"

const SettingProvider = () => {
  const { setBreadcrumbs } = useSubheader();
  const {formatMessage} = useIntl()
  let { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search.slice(1, 100000));

  useLayoutEffect(() => {
    setBreadcrumbs([
        {
        title: formatMessage({ defaultMessage: "Cài đặt" }),
        },
        {
        title: formatMessage({ defaultMessage: "Kết nối mở rộng" }),
        },
        {
        title: id
        },
    ]);
  }, []);

  const { data: dataBrand, loading: loadingBrand } = useQuery(query_sme_brands, {
    fetchPolicy: 'cache-and-network'
  });

  const OPTIONS_BRAND = useMemo(() => {
    if(!dataBrand || !dataBrand?.sme_brands?.length) return []
    return dataBrand?.sme_brands?.filter(brand => brand?.is_default)?.map(brand => ({
      value: brand?.id,
      label: brand?.name
    }))
  }, [dataBrand])

  const currentBrands = useMemo(() => {
    if(params?.system_code != 'vietful') return null
    if(!!params?.brand) {
      return OPTIONS_BRAND?.find(option => option?.value == +params?.brand)
    }
    return OPTIONS_BRAND?.[0]
  }, [OPTIONS_BRAND, params?.brand])

  return (
    <>
      <LoadingDialog show={loadingBrand} />
      <Helmet titleTemplate={formatMessage({ defaultMessage: `Kết nối mở rộng {key}` },{ key: " - UpS" })} 
      defaultTitle={formatMessage({ defaultMessage: `Kết nối mở rộng {key}` },{ key: " - UpS" })}>
        <meta name="description" content={formatMessage({ defaultMessage: `Kết nối mở rộng {key}` },{ key: " - UpS" })}/>
      </Helmet>
      <Card>
        <CardBody>
        {params?.system_code == 'vietful' && <div className="d-flex w-100 mb-4" style={{ zIndex: 1 }}>
          <div style={{ flex: 1 }}>
            <ul className="nav nav-tabs">
              {!loadingBrand && OPTIONS_BRAND.map((tab) => {
                const isTabActive = tab?.value == currentBrands?.value;

                return (
                  <li
                    key={`tab-${tab?.value}`}
                    onClick={() => {
                      history.push(`${location.pathname}?${queryString.stringify({
                        ...params,
                        page: 1,
                        brand: tab?.value
                      })}`);
                    }}
                  >
                    <a style={{ fontSize: "16px" }} className={`nav-link ${isTabActive ? "active" : ""}`}>
                      {tab?.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>}
        <TableProviderProduct currentBrands={currentBrands} system_code={params?.system_code}/>
        </CardBody>
      </Card>
     

      <div
        id="kt_scrolltop1"
        className="scrolltop"
        style={{ bottom: 80 }}
        onClick={() => {
          window.scrollTo({
            letf: 0,
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }}
      >
        <span className="svg-icon">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")}
            title={" "}
          ></SVG>
        </span>
      </div>
    </>
  )
}

export default SettingProvider