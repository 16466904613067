import gql from "graphql-tag";

export default gql`
  mutation prvConnectProvider(
    $client_id: String,
    $client_secret: String,
    $provider_code: String!,
    $app_id: String, 
    $tax_code: String, 
    $type: String,
    $sme_brand_id: Int
  ) {
    prvConnectProvider(
        client_id: $client_id,
        client_secret: $client_secret,
        provider_code: $provider_code,
        tax_code: $tax_code, 
        type: $type, 
        app_id: $app_id,
        sme_brand_id: $sme_brand_id
    ) {
      success
      message
    }
  }
`;
