import gql from 'graphql-tag';

export default gql`
    mutation sendOtpCreateUser($email: String!, $token: String!) {
        sendOtpCreateUser(email: $email, token: $token) {
            message
            success
        }
    }
`;
