import { useMutation, useQuery } from '@apollo/client';
import { createApolloClientSSR } from "../../../../apollo";
import mutateAuthSSO from '../../../../graphql/authen-sso'
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import LoadingDialog from '../../FrameImage/LoadingDialog';
import query_user from '../../../../graphql/user-me'
import queryString from 'querystring'
import { getUserByToken } from "../_redux/authCrud";
import { actions } from "../../Auth/_redux/authRedux";
import mutate_authSecondFactor from '../../../../graphql/mutate_authSecondFactor';
import { useToasts } from 'react-toast-notifications';
import firebase, { auth } from '../../../../firebase';
import { Card } from '../../../../_metronic/_partials/controls';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';


const SecondFactor = (props) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search.slice(1, 100000))
    const [authSecondFactor] = useMutation(mutate_authSecondFactor)
    const {addToast} = useToasts()
    const [result, setResult] = useState(false)
    const [loading, setLoading] = useState(true)

    useMemo(async () => {
        setLoading(true)
        try {
            const {data, loading} = await authSecondFactor({
                variables: {
                    token: params?.token
                }
            })

            if (data?.authSecondFactor?.success) {
                addToast("Xác minh đăng nhập thành công", {appearance: 'success'})
                setResult(true)
                setLoading(false)
            } else {
                setResult(false)
                setLoading(false)
                addToast(data?.authSecondFactor?.message || "Token không hợp lệ", {appearance: 'error'})
                return
            }
        } catch (error) {
            console.log(error)
            return
        }
    }, [params?.token]);

    return (
        <>
            {!loading && <div className='d-flex justify-content-center align-items-center' style={{width: '100vw', height: '100vh'}}>
                    <img src={toAbsoluteUrl(`/media/logos/${result ? 'check.png' : 'error.png'}`)} height={150} width={150} alt='Image'/>
            </div>}
        </>
    )
};

export default connect(null, actions)(SecondFactor)