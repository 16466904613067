import React, { memo, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import Select from 'react-select'
import query_sme_brands from '../../../../../graphql/query_sme_brands';
import mutate_scUpdateStore from '../../../../../graphql/mutate_scUpdateStore';

const UpdateBrandDialog = ({
    showDialog,
    id,
    onHide,
    refetch
}) => {
    const {formatMessage} = useIntl()
    const {addToast} = useToasts()
    const [brands, setBrands] = useState(null)

    const { data: dataBrand, loading: loadingBrand } = useQuery(query_sme_brands, {
        fetchPolicy: 'cache-and-network'
    });

    const [mutate, {loading}] = useMutation(mutate_scUpdateStore)

    const optionBrands = useMemo(() => {
        if(!dataBrand?.sme_brands?.length) return []
        let _options = dataBrand?.sme_brands?.map(brand => {
            return { label: brand.name, value: brand.id}
        })
        return _options
    }, [dataBrand])

    useEffect(() => {
        setBrands(id?.sme_brand_id)
    }, [id])

    return (
        <Modal
            show={showDialog}
            aria-labelledby="example-modal-sizes-title-md"
            centered
            onHide={onHide}
            backdrop={true}
            // dialogClassName={'body-dialog-connect'}
        >
            <Modal.Header>
                <Modal.Title>
                    {formatMessage({defaultMessage:'Cập nhật nhãn hàng'})}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
                <i
                    className="fas fa-times"
                    onClick={onHide}
                    style={{ position: 'absolute', top: -45, right: 20, fontSize: 20, cursor: 'pointer' }}
                />
                <div className='row'>
                    <Select
                        className='w-100'
                        placeholder={formatMessage({defaultMessage: 'Chọn nhãn quản lý'})}
                        value={optionBrands?.find(item => item?.value == brands)}
                        options={optionBrands}
                        onChange={value => {
                            if (!value) {
                                setBrands(null)
                                return
                            }
                            setBrands(value?.value)
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="form" style={{ borderTop: '1px solid #dbdbdb', justifyContent: 'end', paddingTop: 10, paddingBottom: 10 }} >
                <div className="form-group">
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-elevate mr-3"
                        style={{ width: 100 }}
                    >
                        Hủy
                    </button>
                    <button
                        type="button"
                        onClick={async () => {
                            let {data} = await mutate({
                                variables: {
                                    store_id: +id?.id,
                                    sme_brand_id: +brands
                                }
                            })
                            if(data?.scUpdateStore?.success) {
                                addToast(formatMessage({defaultMessage: 'Cập nhật nhãn hàng cho gian hàng thành công. '}), {appearance: 'success'})
                            } else {
                                addToast(data?.scUpdateStore?.message || formatMessage({defaultMessage: 'Cập nhật nhãn hàng cho gian hàng thất bại. '}), {appearance: 'error'})
                            }
                            refetch()
                            onHide()
                        }}
                        className="btn btn-primary btn-elevate mr-3"
                        style={{ width: 100 }}
                        disabled={loading}
                    >
                        Cập nhật
                    </button>
                </div>
            </Modal.Footer>
        </Modal >
    )
};

export default memo(UpdateBrandDialog);