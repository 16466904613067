import { auth } from "../firebase";

export default function setupAxios(axios, store) {  
  console.log(auth.currentUser)  
  axios.interceptors.request.use(
    async config => {      
      if (config?.isSubUser) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('jwt')}`;
      } else {
        let jwt = localStorage.getItem('jwt')
        if (jwt) {
          config.headers.Authorization = `Bearer ${jwt}`;
        }
      }
      if (config?.skipHeader) {
        delete config.headers.Authorization
      }
      return config;
    },
    err => Promise.reject(err)
  );
}
