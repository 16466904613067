import gql from 'graphql-tag';

export default gql`
query scGetIgnoredVariantPushInventory($page: Int!, $per_page: Int!, $store_id: Int, $search_variant: String) {
    scGetIgnoredVariantPushInventory(page: $page, per_page: $per_page, store_id: $store_id, search_variant: $search_variant) {
            list_variant {
                sc_product_attributes_value
                id
                sku
                product {
                    id
                    name
                    productVariantAttributes {
                        id
                        name
                    }
                    variantAttributeValues {
                        id
                        ref_index
                        sc_variant_attribute_id
                        value
                        scVariantValueAssets {
                            id
                            sme_url
                        }
                    }
                    productAssets {
                        id
                        sme_url
                    }
                }
            }
            total
  }
}
`;
