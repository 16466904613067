import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import queryString from 'querystring';
import { Card } from '../../../../_metronic/_partials/controls';
import { useIntl } from 'react-intl';
import mutate_authEmailAndPassword from '../../../../graphql/mutate_authEmailAndPassword';
import auth_checkEmailExist from '../../../../graphql/auth_checkEmailExist';
import { useMutation, useQuery } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import LoadingDialog from '../../Products/product-edit/LoadingDialog';
import query_authIamLoginSession from '../../../../graphql/query_authIamLoginSession';
import dayjs from 'dayjs';
import firebase, { auth } from '../../../../firebase';
import { useDispatch, connect } from 'react-redux';
import { actions } from "../../Auth/_redux/authRedux";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios'

export const authEmailAndPassword = async (email, password, deviceId, token ) => {
    try {
        const response = await axios.post(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
            query: `
                mutation authEmailAndPassword($token: String, $email: String!, $password: String!, $device_id: String) {
                    authEmailAndPassword(email: $email, password: $password, token: $token, device_id: $device_id) {
                        attempts
                        access_token
                        message
                        success
                        user_id
                        is_second_factor
                    }
                }
            `,
            variables: {
                email,
                password,
                token,
                device_id: deviceId
            },
        },
        {
            skipHeader: true
        }
    );

        return response.data;
    } catch (error) {
        console.error("Error occurred during mutation", error);
        throw error;
    }
};

function Notify(props) {
    const location = useLocation();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [attemptNum, setAttempNum] = useState(0);
    const [timePoll, setTimePoll] = useState(5000)
    const [visitorId, setVisitorId] = useState(null);
    const dispatch = useDispatch();
    const [
        mutateCheckEmailExist,
        { loading: loadingCheckEmailExist },
    ] = useMutation(auth_checkEmailExist);
    const {addToast} = useToasts();

    FingerprintJS.load()
        .then(fp => fp.get())
        .then(result => {
            // This is the visitor identifier
            const visitorId = result.visitorId;
            setVisitorId(visitorId)
        })
        .catch(error => {
            console.error('Error generating visitor ID:', error);
        });

    const { email, password } = location.state || {};

    const maskEmail = (email) => {
        if (!email) return '';
        const [localPart, domain] = email.split('@');
        const maskedLocalPart =
            localPart.length > 2
                ? `${localPart[0]}***${localPart[localPart.length - 1]}`
                : localPart;
        const [domainName, domainExtension] = domain.split('.');
        const maskedDomainName =
            domainName.length > 2
                ? `${domainName[0]}***${domainName[domainName.length - 1]}`
                : domainName;
        return `${maskedLocalPart}@${maskedDomainName}.${domainExtension}`;
    };

    const { data, error, loading: loadingGetToken, refetch } = useQuery(query_authIamLoginSession, {
        fetchPolicy: 'cache-and-network',
        pollInterval: timePoll
    });

    useMemo(async () => {
        if (data?.authIamLoginSession?.loginAt) {
            const loginAtTime = dayjs(data?.authIamLoginSession.loginAt).unix();
            const currentTime = dayjs().unix();
            const timeDiff = (currentTime - loginAtTime) / (1000 * 60);
    
            if (timeDiff >= 10) {
                setTimePoll(0);
                return
            }
            if(data?.authIamLoginSession?.accessToken) {
                setTimePoll(0);
                await firebase.auth().signInWithCustomToken(data?.authIamLoginSession?.accessToken).then(async user => {
                    localStorage.setItem('jwt', user?.user?.Aa);
                    dispatch(props.login(user?.user?.Aa));
                    history.push('/');
                    return user;
                })
                return
            }
        }
    }, [data]);

    const handleSendEmail = async () => {
        setLoading(true);
        try {
            if (attemptNum >= 5) {
                grecaptcha.ready(function() {
                    grecaptcha
                        .execute(process.env.REACT_APP_CAPTCHA_KEY, {
                            action: 'submit',
                        })
                        .then(async function(token) {
                            let { data } = await authEmailAndPassword(
                                email,
                                password,
                                visitorId,
                                token
                            );
                            setLoading(false);
                            if (data?.authEmailAndPassword?.success) {
                                addToast(
                                    formatMessage(
                                        {
                                            defaultMessage:
                                                'Gửi lại email thành công',
                                        },
                                        
                                    ), { appearance: 'success' }
                                );
                            } else {
                                addToast(
                                    data?.authEmailAndPassword?.message ||
                                        intl.formatMessage({
                                            defaultMessage:
                                                'Có lỗi xảy ra!',
                                        }),
                                    { appearance: 'error' }
                                );
                                let {
                                    data: dataCheckEmailExist,
                                } = await mutateCheckEmailExist({
                                    variables: { email: email },
                                });
                                if (
                                    !!dataCheckEmailExist?.checkEmailExist
                                        ?.attempts
                                ) {
                                    setAttempNum(
                                        dataCheckEmailExist?.checkEmailExist
                                            ?.attempts
                                    );
                                }
                            }
                        });
                });
            }
            let { data } = await authEmailAndPassword(email, password,visitorId);
            setLoading(false);
            if (data?.authEmailAndPassword?.success) {
                addToast(
                    formatMessage(
                        { defaultMessage: 'Gửi lại email thành công' },
                        
                    ),
                    { appearance: 'success' }
                );
            } else {
                setLoading(false);
                addToast(
                    data?.authEmailAndPassword?.message ||
                        formatMessage({
                            defaultMessage:
                                'Có lỗi xảy ra!',
                        }),
                    { appearance: 'error' }
                );
                let {
                    data: dataCheckEmailExist,
                } = await mutateCheckEmailExist({
                    variables: { email: email },
                });
                if (!!dataCheckEmailExist?.checkEmailExist?.attempts) {
                    setAttempNum(
                        dataCheckEmailExist?.checkEmailExist?.attempts
                    );
                }
            }
        } catch (error) {
            addToast(
                formatMessage({
                    defaultMessage: 'Tài khoản hoặc mật khẩu không chính xác',
                }), {appearance: 'error'}
            );
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh', // Full viewport height to center vertically
            }}
        >
            {/* <LoadingDialog show={loading || loadingCheckEmailExist} /> */}
            <Card
                style={{ width: '60vw', height: '60vh' }}
                className="d-flex align-items-center justify-content-center"
            >
                <div className="d-flex flex-column align-items-center justify-content-center flex-root mt-10">
                    {history?.length > 1 && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '10%',
                                left: '10%',
                                cursor: 'pointer',
                            }}
                            onClick={() => history.goBack()}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#ff5629"
                                className="bi bi-arrow-left"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                                />
                            </svg>
                        </div>
                    )}
                    <h5
                        className="font-weight-boldest mb-12"
                        style={{ fontSize: 22 }}
                    >
                        Xác minh bằng liên kết gửi qua Email
                    </h5>
                    <p>
                        Vui lòng nhấn vào liên kết xác thực đã được gửi đến địa
                        chỉ Email
                    </p>
                    <p>{maskEmail(email)}</p>
                    <img
                        src={toAbsoluteUrl('/media/email/Email_copy.png')}
                        style={{ width: '10vw' }}
                    />
                    <button
                        className={`btn btn-primary mt-8`}
                        style={{ width: '47%' }}
                        onClick={async () => {
                            handleSendEmail()
                        }}
                        disabled={loading || loadingCheckEmailExist}
                    >
                        {loading || loadingCheckEmailExist ? <span className="spinner spinner-white"></span> : formatMessage({ defaultMessage: 'Gửi lại mail' })}
                    </button>
                </div>
            </Card>
        </div>
    );
}

export default connect(null, actions)(Notify)
