import gql from "graphql-tag";

export default gql`
  query userCountProductSyncFullfillment($smeBrandId: Int, $connectedProviderId: Int!, $searchText: String) {
    userCountProductSyncFullfillment(smeBrandId: $smeBrandId, connectedProviderId: $connectedProviderId, searchText: $searchText) {
        countNotSynced
        countSyncedFail
        countSyncedSuccess
    }
  }
`;

