import gql from 'graphql-tag';

export default gql`
    mutation authEmailAndPassword($token: String, $email: String!, $password: String!, $device_id: String) {
        authEmailAndPassword(email: $email, password: $password, token: $token, device_id: $device_id) {
            attempts
            access_token
            message
            success
            user_id
            is_second_factor
        }
    }
`;
