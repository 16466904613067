import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import clsx from "clsx";
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import query_sme_brands from "../../../../../graphql/query_sme_brands";
import query_sc_ads from "../../../../../graphql/query_sc_ads";
import {useLocation} from 'react-router-dom'
import queryString from 'querystring'
import LoadingDialog from "../../../ProductsStore/product-new/LoadingDialog";
import { useIntl, FormattedMessage } from "react-intl";
import Pagination from "../../../../../components/Pagination";
import AuthorizationWrapper from "../../../../../components/AuthorizationWrapper";
import { TooltipWrapper } from "../../../Finance/payment-reconciliation/common/TooltipWrapper";
import ModalAddAds from "./ModalAddAds";
import { Dropdown } from "react-bootstrap";
import AdsConfirmUnlinkDialog from "./AdsConfirmUnlinkDialog";
import scAdsAuthorizationUrl from "../../../../../graphql/scAdsAuthorizationUrl";
import query_stores_channel from '../../../../../graphql/query_stores_channel';
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";


export default function AdsTable() {
    const params = queryString.parse(useLocation().search.slice(1, 100000))
    const {formatMessage} = useIntl()
    const [showAdd, setShowAdd] = useState(false)
    const [storeUnlinkCurrent, setStoreUnlinkCurrent] = useState()
    const [authorize, { data: dataAuthozie }] = useLazyQuery(scAdsAuthorizationUrl)

    const { data:dataStore, loading: loadingDataStore } = useQuery(query_stores_channel, {
      fetchPolicy: 'network-only',
      variables: {
        context: 'order'
      }
    })

    let page = useMemo(() => {
        try {
          let _page = Number(params.page)
          if (!Number.isNaN(_page)) {
            return Math.max(1, _page)
          } else {
            return 1
          }
        } catch (error) {
          return 1
        }
      }, [params.page])
    let limit = useMemo(() => {
        try {
          let _value = Number(params.limit)
          if (!Number.isNaN(_value)) {
            return Math.max(25, _value)
          } else {
            return 25
          }
        } catch (error) {
          return 25
        }
    }, [params.limit]);
    const { data, loading, refetch, error } = useQuery(query_sc_ads, {
            fetchPolicy: 'network-only'
    });

    // const totalRecord = data?.sme_brands_aggregate?.aggregate?.count
    // const totalPage = Math.ceil(totalRecord / limit)

    useMemo(() => {
      if (!!dataAuthozie && !!dataAuthozie.scAdsAuthorizationUrl && !!dataAuthozie.scAdsAuthorizationUrl.authorization_url) {
        window.location.replace(dataAuthozie.scAdsAuthorizationUrl.authorization_url)
      }
  
    }, [dataAuthozie]);
    
    const STATUS = {
        DISCONNECT: 0,
        CONNECTED: 1,
        LOST_CONNECTED: 2,
    }

    const statusStore = useCallback((row) => {
        switch (row?.status) {
          case STATUS['CONNECTED']:
            return <span style={{ color: '#3DA153' }}><FormattedMessage defaultMessage="Đã kết nối" /></span>
          case STATUS['LOST_CONNECTED']:
            return <div className="d-flex align-items-center justify-content-start">
              <span style={{ color: '#FF0000' }}><FormattedMessage defaultMessage="Mất kết nối" /></span>
              <TooltipWrapper note={formatMessage({ defaultMessage: "Gian hàng bị hết hạn uỷ quyền, vui lòng kết nối lại." })}>
                <img style={{ cursor: 'pointer', marginLeft: '4px' }} src={toAbsoluteUrl("/media/warningsvg.svg")}></img>
              </TooltipWrapper>
    
            </div>
          default:
            return <span style={{ color: '#FF0000' }}><FormattedMessage defaultMessage="Ngắt kết nối" /></span>
        }
    }, [data])

    const formatAuthorizeTime = useCallback((authorization_expired_at) => {
      let view;
    
      if (!authorization_expired_at) {
        view = <div className="d-flex align-items-center justify-content-center"><span>
          {formatMessage({ defaultMessage: 'Không giới hạn' })}
        </span></div>
      }
    
      if (!!authorization_expired_at) {
        // Always add 7 hours to the provided time
        const expiredAtPlus7h = dayjs(authorization_expired_at).add(7, 'hours');
        let [daysStill, hoursStill] = [
          expiredAtPlus7h.diff(dayjs(), 'days'),
          expiredAtPlus7h.diff(dayjs(), 'hours'),
        ];
        const hours = hoursStill - daysStill * 24;
        const textStill = formatMessage({ defaultMessage: `còn {time} {date_type}` }, {
          time: daysStill > 0 ? daysStill : (hours <= 0 ? '0' : hours > 10 ? hours : `0${hours}`),
          date_type: (daysStill > 0 || hours <= 0) ? 'ngày' : 'giờ'
        });
    
        view = <div className="d-flex align-items-center justify-content-center">
          <span className="mr-2">
            {expiredAtPlus7h.format('YYYY-MM-DD HH:mm')}
          </span>
          (<span className={clsx(daysStill < 8 && 'text-danger')}>
            {textStill}
          </span>)
        </div>
      }
    
      return view;
    }, []);
    

    const actionStore = useCallback((row) => {
        return (
          <>
            <Dropdown drop='down' >
              <Dropdown.Toggle className='btn-outline-secondary' >
                {formatMessage({ defaultMessage: 'Chọn' })}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <AuthorizationWrapper keys={['setting_ads_action']}>
                    <Dropdown.Item className="mb-1 d-flex" >
                      <div onClick={e => {
                        authorize({
                          variables: {
                            connector_channel_code: 'shopee'
                          }
                        })
                      }} >
                        <FormattedMessage defaultMessage="Kết nối lại" />
                      </div>
                    </Dropdown.Item>
                    {
                        !!row.status &&<Dropdown.Item className="mb-1 d-flex" >
                      
                        <div 
                        onClick={e => setStoreUnlinkCurrent(row.id)} 
                        >
                          <FormattedMessage defaultMessage="Ngắt kết nối" />
                        </div>
    
                        </Dropdown.Item>
                    }
                </AuthorizationWrapper>
              </Dropdown.Menu>
            </Dropdown>
    
          </>
        );
      }, [data])

      const nameStore = (row) => {
        let channel = (dataStore?.op_connector_channels || []).find(_channel => _channel.code == row.connector_channel_code)
        console.log(channel)
        return (
          <div>
            {!!channel && <img src={channel.logo_asset_url} className={` mr-2`} style={{ width: 24, height: 24 }} />}
            <span className={`font-size-h7`}>
              {row.name}
            </span>
          </div>
        );
      }
    return (
        <>
        <LoadingDialog show={loading} />
        {showAdd && <ModalAddAds isShow={showAdd} onHide={() => {
            setShowAdd(false)
        }}/>}

{
        !!storeUnlinkCurrent && <AdsConfirmUnlinkDialog
          refetch={refetch}
          show={!!storeUnlinkCurrent}
          storeUnlinkCurrent={storeUnlinkCurrent}
          onHide={() => {
            setStoreUnlinkCurrent(null)
          }}
        />
      }
        <div className="d-flex justify-content-end my-3">
                <AuthorizationWrapper keys={['setting_ads_action']}>
                    <button onClick={() => setShowAdd(true)} className="btn btn-primary">{formatMessage({defaultMessage: 'Thêm tài khoản'})}</button>
                </AuthorizationWrapper>
            </div>
        <table className="table table-borderless table-vertical-center fixed">
            <thead
            style={{
                borderBottom: '1px solid #F0F0F0',
                borderRight: '1px solid #d9d9d9',
                borderLeft: '1px solid #d9d9d9',
                background: "#F3F6F9",
                fontWeight: "bold",
                fontSize: "14px",
                position: 'sticky',
                top: 44,
                zIndex: 10
            }}
            >
            <tr className="font-size-lg">
                <th style={{ fontSize: '14px' }} >{formatMessage({ defaultMessage: "Tên tài khoản QC" })}</th>
                <th style={{ fontSize: '14px' }} >{formatMessage({ defaultMessage: "Trạng thái" })}</th>
                <th style={{ fontSize: '14px', textAlign: 'center' }} >{formatMessage({ defaultMessage: "Thời hạn ủy quyền" })}</th>
                <th style={{ fontSize: '14px' }} >{formatMessage({ defaultMessage: "Thời gian kết nối" })}</th>
                <th style={{ width: "150px", fontSize: '14px' }} className="text-center">{formatMessage({ defaultMessage: "Thao tác" })}</th>
            </tr>
            </thead>
            <tbody>
                
                {!loading && data?.sc_ads?.filter(ad => (ad?.status !== 0))?.map(ad => {
                    return <tr>
                            <td>
                                <span>{nameStore(ad)}</span>
                            </td>

                            <td>{<span>{statusStore(ad)}</span>}</td>
                            <td>{formatAuthorizeTime(ad?.authorization_expired_at)}</td>
                            <td>{ad?.last_connected_at}</td>
                            <td className="text-center">
                                {actionStore(ad)}
                            </td>
                    </tr>
                })}

            </tbody>
        </table>
        </>
    )
}
