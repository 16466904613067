import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';

const ModalChangePassword = ({ show, onHide, onSubmit }) => {
    const { formatMessage } = useIntl();
    return (
        <Modal
            show={show}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
            backdrop={true}
        >
            <Modal.Header>
                <Modal.Title>
                    {formatMessage({
                        defaultMessage: 'Yêu cầu thay đổi mật khẩu',
                    })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
                <div className="text-center">
                    <div className="mb-8">
                        <span>
                            Mật khẩu của bạn cần được thay đổi sau 6 tháng hoạt
                            động. Vui lòng thay đổi mật khẩu{' '}
                        </span>
                        <span
                            className="text-primary cursor-pointer"
                            onClick={onSubmit}
                        >
                            tại đây.
                        </span>
                    </div>
                    <div className="form-group mb-0">
                        <button
                            className={`btn btn-primary font-weight-bold`}
                            style={{ width: 120 }}
                            onClick={() => {
                                onHide();
                            }}
                        >
                            <span className="font-weight-boldest">
                                {formatMessage({ defaultMessage: 'Đóng' })}
                            </span>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalChangePassword;
