import gql from 'graphql-tag';

export default gql`
    query authIamLoginSession {
        authIamLoginSession {
            accessToken
            deviceId
            id
            isTrusted
            loginAt
            userAgent
            userId
        }
    }
`;
