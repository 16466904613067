/**
 * Entry application component used to compose providers and render Routes.
 * */
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { ToastProvider } from 'react-toast-notifications';
import { HelmetProvider } from 'react-helmet-async';
import DangerouslySetHtmlContent from "../components/DangerouslySetHtmlContent";
import { useEffect } from "react";

export default function App({ store, persistor, basename }) {
  useEffect(() => {
      const recaptchaKey = process.env.REACT_APP_CAPTCHA_KEY;
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
      script.async = true;
      script.defer = true;

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
  }, []); 
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            <HelmetProvider>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <ToastProvider
                    autoDismissTimeout={5000}
                    autoDismiss
                  >
                    <AuthInit>
                      <Routes />
                    </AuthInit>
                  </ToastProvider>
                </I18nProvider>
              </MaterialThemeProvider>
            </HelmetProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>

      <DangerouslySetHtmlContent html={`
      <script async src="https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
    
        gtag('config', '${process.env.REACT_APP_GA_ID}');
      </script>
      `} />
      <DangerouslySetHtmlContent html={`
        <script type="text/javascript">
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "iqa7e4mde7");
        </script>
      `} />
    </Provider>
  );
}
