import gql from 'graphql-tag';

export default gql`
    mutation authSecondFactor($token: String!) {
        authSecondFactor(token: $token) {
            message
            success
            access_token
        }
    }
`;
