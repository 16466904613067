import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import HoverImage from '../../../../components/HoverImage';
import InfoProduct from '../../../../components/InfoProduct';
import { useFormikContext } from 'formik';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import mutate_scDeleteIgnoredVariantPushInventory from '../../../../graphql/mutate_scDeleteIgnoredVariantPushInventory';
import Spinner from 'react-bootstrap/Spinner';
import { useToasts } from 'react-toast-notifications';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import Collapse from 'react-bootstrap/Collapse';
import query_scGetIgnoredVariantPushInventory from '../../../../graphql/query_scGetIgnoredVariantPushInventory';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModalAddVariantInventory from './ModalAddVariantInventory';
import mutate_scAddIgnoredVariantPushInventory from '../../../../graphql/mutate_scAddIgnoredVariantPushInventory';
import { Checkbox } from '../../../../_metronic/_partials/controls';
import PaginationModal from '../../../../components/PaginationModal';

const gridStyle = { display: 'grid', gridTemplateColumns: '9fr 1fr' }
const TableVariantExcept = ({ store_id }) => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(5)
    const { formatMessage } = useIntl()
    const { values, setFieldValue, initialValues } = useFormikContext()
    const [dialogVariant, setDialogVariant] = useState(false)
    const { addToast } = useToasts()
    const [getIgnoredVariantPushInventory, { data, loading }] = useLazyQuery(query_scGetIgnoredVariantPushInventory, {
        variables: {
            page: page,
            per_page: limit,
            store_id,
        },
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            setFieldValue("totalIgnoredVariantPushInventory", data?.scGetIgnoredVariantPushInventory?.total)
        }
    })

    useEffect(() => {
        if (!!initialValues) getIgnoredVariantPushInventory()
    }, [initialValues])

    const exceptVariant = data?.scGetIgnoredVariantPushInventory?.list_variant ?? []
    const [collapse, setCollapse] = useState(true);

    const totalRecord = data?.scGetIgnoredVariantPushInventory?.total;
    const totalPage = Math.ceil(totalRecord / limit)

    const [deleteVariantInventoryExcept, { loading: removing }] = useMutation(mutate_scDeleteIgnoredVariantPushInventory,
        {
            awaitRefetchQueries: true,// refetchQueries: ['scGetIgnoredVariantPushInventory'],
            onCompleted: (data) => {
                if (data?.scDeleteIgnoredVariantPushInventory?.success == 1) {
                    getIgnoredVariantPushInventory()
                    addToast(data?.scDeleteIgnoredVariantPushInventory?.message || formatMessage({ defaultMessage: "Xoá hàng hoá thành công!" }), { appearance: "success" })
                }
                else addToast(data?.scDeleteIgnoredVariantPushInventory?.message || formatMessage({ defaultMessage: "Xoá hàng hoá thất bại!" }), { appearance: "error" })
            },
            onError: (data) => {
                addToast(data?.message || formatMessage({ defaultMessage: "Có lỗi xảy ra!" }), { appearance: "error" })
            }
        }
    );

    const onDeleteVariant = (variantId) => {
        deleteVariantInventoryExcept({
            variables: {
                sc_variant_id: variantId,
                store_id
            }
        })
    }

    const [addVariantInventoryExcept, { loading: adding }] = useMutation(mutate_scAddIgnoredVariantPushInventory,
        {
            awaitRefetchQueries: true,// refetchQueries: ['scGetIgnoredVariantPushInventory'],
            onCompleted: (data) => {
                if (data?.scAddIgnoredVariantPushInventory?.success == 1) {
                    getIgnoredVariantPushInventory()
                    addToast(data?.scAddIgnoredVariantPushInventory?.message || formatMessage({ defaultMessage: "Thêm hàng hoá ngoại trừ thành công" }), { appearance: "success" })
                }
                else addToast(data?.scAddIgnoredVariantPushInventory?.message || formatMessage({ defaultMessage: "Thêm hàng hoá ngoại trừ thất bại" }), { appearance: "error" })
            },
            onError: (data) => {
                addToast(data?.message || formatMessage({ defaultMessage: "Có lỗi xảy ra!" }), { appearance: "error" })
            }
        }
    );

    return (
        <div>
            <div className='d-flex align-items-center mb-2'>
                <Checkbox
                    isSelected={!!values['exceptVariants']}
                    onChange={(e) => {
                        const newValue = !!e.target.checked;
                        setFieldValue('exceptVariants', newValue)
                    }}
                    disabled={!Boolean(!!values['typePush'])}
                />
                {formatMessage({ defaultMessage: "Ngoại trừ một số hàng hoá" })}
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {formatMessage({ defaultMessage: "Các hàng hoá được chọn sẽ không được đồng bộ tồn từ hệ thống sang kênh bán." })}
                        </Tooltip>
                    }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ml-1 bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                </OverlayTrigger>
            </div>
            <button
                disabled={!values['exceptVariants'] || !Boolean(!!values['typePush'])}
                onClick={() => setDialogVariant(true)}
                className={`${!values['exceptVariants'] ? "btn-dark" : "btn-primary"} p-3 btn mt-2 mt-2`}
                style={{ borderRadius: "6px" }}>
                {formatMessage({ defaultMessage: "Thêm nhanh hàng hoá" })}
            </button>
            {!!values['exceptVariants'] && <div className="mt-4" style={{ border: '1px solid gray' }}>
                <div style={{ background: '#F3F6F9', fontWeight: 'bold', fontSize: '14px', display: 'grid', ...gridStyle, padding: "16px", borderBottom: '1px solid gray' }}>
                    <div width="90%">{formatMessage({ defaultMessage: "Hàng hoá" })} {(loading || removing || adding) && <Spinner animation="border" size="sm" />}</div>
                    <div width="10%" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{formatMessage({ defaultMessage: "Thao tác" })}
                        <Expand id="__list_ignored_variant_push"
                            onClick={(e) => {
                                setCollapse(!collapse);
                                e.currentTarget.style.transform = `rotate(${180 - (collapse ? 0 : 180)}deg)`
                            }}
                        />
                    </div>
                </div>
                <Collapse in={collapse}>
                    <div>
                        {exceptVariant?.map((elm, index) => {
                            return (
                                <RowTableProduct
                                    key={index}
                                    onDeleteVariant={(variant_id) => {
                                        onDeleteVariant(variant_id)
                                    }}
                                    elm={elm}
                                    isLast={index == exceptVariant?.length - 1}
                                    disabled={!Boolean(!!values['typePush'])}
                                />
                            )
                        })}
                        <div style={{ margin: "0 12px" }}>
                            <PaginationModal
                                page={page}
                                totalPage={totalPage}
                                limit={limit}
                                onSizePage={(limit) => {
                                    setLimit(+limit); setPage(1)
                                }}
                                totalRecord={totalRecord}
                                count={exceptVariant?.length}
                                onPanigate={(page) => {
                                    setPage(+page)
                                }}
                                emptyTitle={formatMessage({ defaultMessage: 'Chưa có hàng hoá' })}
                                optionPagination={[{ label: 5, value: 5 }, { label: 10, value: 10 }, { label: 20, value: 20 }]}
                            />
                        </div>
                    </div>
                </Collapse>
            </div>}
            {dialogVariant &&
                <ModalAddVariantInventory
                    totalVariant={data?.scGetIgnoredVariantPushInventory?.total}
                    variantAdded={data?.scGetIgnoredVariantPushInventory?.list_variant}
                    show={dialogVariant}
                    onHide={(variants) => {
                        setDialogVariant(false)
                        if (!!variants?.length) {
                            addVariantInventoryExcept({
                                variables: {
                                    list_ignored_variant_id: variants?.map(_ => _?.id),
                                    store_id
                                }
                            })
                        }
                    }} />
            }
        </div>
    )
}

const RowTableProduct = ({ onDeleteVariant, elm, isLast = false, disabled }) => {
    const combinationVariant = useMemo(() => {
        let hasAttribute = elm?.product?.variantAttributeValues?.length > 0;
        if (hasAttribute) {
            let combinationVariant = [];

            let _sc_product_attributes_value = elm?.sc_product_attributes_value ? JSON.parse(elm?.sc_product_attributes_value) : []
            let _sc_product_variant_attr = elm?.product.productVariantAttributes

            let _variantAttributeValue = elm?.product?.variantAttributeValues?.filter(_value => {
                return _sc_product_attributes_value.includes(_value.ref_index)
            })
            _variantAttributeValue.forEach(variant_attr_value => {
                _sc_product_variant_attr.forEach(variant_attr => {
                    if (variant_attr_value.sc_variant_attribute_id == variant_attr.id) {
                        combinationVariant.push(variant_attr_value.value)
                    }
                });
            });
            return combinationVariant.join(' - ')
        }
    }, [elm])

    let assetUrl = useMemo(() => {
        let _asset = null
        try {
            let _sc_product_attributes_value = JSON.parse(elm?.sc_product_attributes_value)
            let _variantAttributeValue = elm?.product?.variantAttributeValues?.find(_value => {
                return _value.scVariantValueAssets?.length > 0 && _sc_product_attributes_value.some(_v => _v == _value.ref_index)
            })
            if (!!_variantAttributeValue) {
                _asset = _variantAttributeValue.scVariantValueAssets[0]
            }
        } catch (error) {

        }
        try {
            if (!_asset) {
                let _variantAttributeValue = _.sortBy(elm?.product?.productAssets || [], 'position')
                if (!!_variantAttributeValue) {
                    _asset = _variantAttributeValue[0]
                }
            }
        } catch (error) {

        }
        return _asset;
    }, [elm])

    return (
        <div style={{ ...gridStyle, padding: "12px 8px", borderBottom: "1px solid gray" }}>
            <div>
                <div className="col-11" style={{ verticalAlign: 'top', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ backgroundColor: '#F7F7FA', width: 90, height: 90, borderRadius: 8, overflow: 'hidden', minWidth: 90, cursor: 'pointer' }}
                        onClick={e => window.open(`/product-stores/edit/${elm?.product?.id}`, '_blank')}
                        className='mr-6'
                    >
                        {!!assetUrl && <HoverImage size={{ width: 320, height: 320 }} defaultSize={{ width: 90, height: 90 }} url={assetUrl?.sme_url} />}
                    </div>
                    <div className='w-100'>
                        <InfoProduct sku={elm?.sku} short={true} name={elm?.product?.name} url={`/product-stores/edit/${elm?.product?.id}`} />
                        <div className='mt-2'>
                            {combinationVariant || ''}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                    disabled={disabled}
                    style={{ backgroundColor: 'transparent' }}
                    onClick={(e) => {
                        e.preventDefault()
                        onDeleteVariant(elm?.id)
                    }}>
                    <img style={{ cursor: 'pointer' }} src={toAbsoluteUrl("/media/svg/trash-red.svg")} alt="" />
                </button>
            </div>
        </div>
    )
}

const Expand = ({ id, onClick }) => <svg id={id} onClick={(e) => { onClick(e) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16" style={{ cursor: "pointer", transition: "0.2s all" }}>
    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
</svg>

export default TableVariantExcept