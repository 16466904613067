/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import queryString from 'querystring';
import React from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import Alert from '../components/Alert';
import BadReviewAlert from '../components/BadReviewAlert';
import RedirectRoute from '../components/RedirectRoute';
import ScheduledFrameErrorAlert from '../components/ScheduledFrameErrorAlert';
import BasePage from "./BasePage";
import { AuthPage, Logout } from "./modules/Auth";
import IdentifyPage from './modules/Auth/pages/IdentifyPage';
import RedirectPage from './modules/Auth/pages/RedirectPage';
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

// const Hotline = memo(() => {
//   const [visible, setvisible] = useState(false)
//   const windowFocused = useWindowFocus();
//   const _refLastLogin = useRef(localStorage.getItem('last_login'))
//   useEffect(() => {
//     if (windowFocused) {
//       let _current = localStorage.getItem('last_login')
//       if (_refLastLogin.current != _current && !!_current) {
//         window.location.reload()
//         _refLastLogin.current = _current
//       }
//     }
//   }, [windowFocused])

//   useEffect(() => {
//     window.changeLastLogin = (_last) => {
//       _refLastLogin.current = _last
//     }
//   }, [])

//   return <>
//     <div>
//       {
//         (!!visible) ? <div className="mock__hotline" style={{
//           right: 61, bottom: 200, position: 'fixed', zIndex: 99
//         }} >
//           <ul>
//             <a href="tel:+84944427799">
//               <img style={{ marginRight: '18px' }} src={toAbsoluteUrl("/media/phoneicon.svg")}></img>
//               <li>0944.427.799</li>
//               <img id="right__hotline" src={toAbsoluteUrl("/media/arrow.svg")}></img>
//             </a>
//             <a href="mailto:support@upbase.vn">
//               <img style={{ marginRight: '18px' }} src={toAbsoluteUrl("/media/Shape.svg")}></img>
//               <li>Email</li>
//               <img id="right__hotline" src={toAbsoluteUrl("/media/arrow.svg")}></img>
//             </a>
//           </ul>
//         </div>
//           : null}

//       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', padding: '10px', background: 'white', borderRadius: '9px 0 0 9px', right: 0, bottom: 200, position: 'fixed', cursor: 'pointer', opacity: visible ? 0.7 : 1, zIndex: 99 }}
//         onClick={() => {
//           setvisible((prev) => !prev)
//         }}>
//         <img style={{ width: '26px' }} src={toAbsoluteUrl("/media/Vector.svg")} />
//       </div>
//     </div>
//   </>
// })

export function Routes() {
  const location = useLocation();
  let { source } = queryString.parse(location.search.slice(1, location.search.length))
  const { isAuthorized } = useSelector(
    ({ auth }) => {
      console.log(auth)
      return {
      isAuthorized: auth.user != null || localStorage?.getItem('fromAgency'),
    }},
    shallowEqual
  );

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <>
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
        }}>
        <Switch>
          <Route
            path="/verify-token"
          >
            <RedirectRoute>
              <RedirectPage />
            </RedirectRoute>
          </Route>
          {!isAuthorized || location.pathname == '/auth/set-password' || location.pathname == '/auth/change-password' ? (
            <Route>
              <AuthPage isSubUser={!!user?.is_subuser} />
            </Route>
          ) : (
            <Redirect from="/auth" to={{
              pathname: (location?.state?.redirect == '/logout' ? null : location?.state?.redirect) || '/'
            }} />
          )}

          <Route path="/identify" component={IdentifyPage} />
          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={Logout} />
          {source == 'chat' && <Redirect to="/identify" />}
          {!isAuthorized ? (
            /*Redirect to `/auth` when user is not authorized*/
            <Redirect to={{
              pathname: !!user?.is_subuser ? "/auth/login-sub-user" : '/auth/login',
              state: {
                redirect: location.pathname == '/logout' ? null : location.pathname
              }
            }} />
          ) : (
            <Layout>
              <ScheduledFrameErrorAlert />
              <BadReviewAlert />
              <Alert />
              <BasePage />
            </Layout>
          )}
        </Switch>
      </BrowserRouter>
    </>
  );
}

